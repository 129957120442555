import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { championships_api_uri } from "../values";
import DropdownMenu from "react-bootstrap/DropdownMenu";
import DropdownItem from "react-bootstrap/DropdownItem";
import { useParams } from "react-router-dom";

import { getInstance as getWasmModuleInstance } from "../RelayHelperWasmAdapter";
import { MainModule, RelayHelperController } from "../TeamsRelaysHelper";
import { RelayHelperBackup } from "./RelayHelperBackup";
import RelayHelperTeam from "./RelayHelperTeam";

export const RelayHelper = () => {
  const { championshipId } = useParams<{
    championshipId: string;
  }>();

  const [relayHelperController, setRelayHelperController] = useState<
    RelayHelperController | undefined
  >(undefined);
  const [relayHelperCore, setRelayHelperCore] = useState<
    MainModule | undefined
  >(undefined);
  useEffect(() => {
    const fetchWasm = async () => {
      const core = await getWasmModuleInstance();
      setRelayHelperCore(core);
      setRelayHelperController(core.RelayHelperController.getInstance());
    };
    fetchWasm().catch(console.error);
  }, []);

  const [pool, setPool] = useState(25);
  const [teams, setTeams] = useState<string[]>([]);
  const [teamName, setTeamName] = useState<string>("");

  const handleTeamChange = (event: any) => {
    const newTeam = event.currentTarget.textContent;
    if (!relayHelperController || !relayHelperCore || newTeam == null) {
      setTeamName("");
    } else {
      relayHelperController.getTeamLoadController(newTeam);
      setTeamName(newTeam);
    }
  };

  useEffect(() => {
    (async () => {
      fetch(championships_api_uri + "/" + championshipId + "/teams")
        .then((res) => res.json())
        .then(
          (result: string[]) => {
            setTeams(result.sort());
          },
          (error) => {
            console.log(error);
          }
        );
    })();
    (async () => {
      fetch(championships_api_uri + "/" + championshipId)
        .then((res) => res.json())
        .then(
          (result: { name: string; date: string; pool: number }) => {
            setPool(result.pool);
          },
          (error) => {
            console.log(error);
          }
        );
    })();
  }, [championshipId]);

  return relayHelperCore && relayHelperController && teams.length !== 0 ? (
    <>
      <Dropdown>
        <Dropdown.Toggle
          variant="secondary"
          id="dropdown-team"
          style={{ width: "100%" }}
        >
          {teamName !== "" ? teamName : "Equipo"}
        </Dropdown.Toggle>
        <DropdownMenu>
          {teams.map((team) => (
            <DropdownItem key={team} eventKey={team} onClick={handleTeamChange}>
              {team}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
      {teamName !== "" && (
        <RelayHelperTeam
          relayHelperCore={relayHelperCore}
          relayHelperController={relayHelperController}
          teamName={teamName}
          championshipId={championshipId}
          pool={pool}
        />
      )}
      <RelayHelperBackup
        relayHelperController={relayHelperController}
        reload={() => {
          const teamNameTemp = teamName;
          setTeamName("");
          setTeamName(teamNameTemp);
        }}
      />
    </>
  ) : (
    <></>
  );
};
