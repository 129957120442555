import React, { useEffect, useState } from "react";
import {
  Accordion,
  Container,
  Modal,
  ProgressBar,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { championships_api_uri, year } from "../values";
import { ParallelTaskExecutor } from "../ParallelTaskExecutor";
import { RelaysSwimmerTable } from "./RelaysSwimmerTable";
import { useAuth0 } from "@auth0/auth0-react";
import { LeveradeWithCompetispyBackupEventResultController } from "../LeveradeWithCompetispyBackupEventResultController";
import { LeveradeParticipant } from "../leverade";
import { EventResultController } from "../EventResultController";

import {
  MainModule,
  Event,
  RelayHelperController,
  Style as StyleWasm,
  SwimmerId,
  TimesPool,
  VectorTeamSummaryController_SummaryEntry,
} from "../TeamsRelaysHelper";
import { styleT, styleToStyleMapKey } from "../models/Style";
import { EventResult } from "../EventResult";
import RelaysSession from "./RelaysSession";

type GetTimeReturnType = {
  time: number;
  distance: 50 | 100;
  style: styleT;
};

type RelayHelperTeamProps = {
  relayHelperCore: MainModule;
  relayHelperController: RelayHelperController;
  teamName: string;
  championshipId: string;
  pool: number;
};
const RelayHelperTeam = ({
  relayHelperCore,
  relayHelperController,
  teamName,
  championshipId,
  pool,
}: RelayHelperTeamProps) => {
  const wasmPool =
    pool == 50 ? relayHelperCore.TimesPool._50 : relayHelperCore.TimesPool._25;
  const [showLoading, setShowLoading] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const eventResultControllerBuilder = isAuthenticated
    ? (
        name: string,
        surname: string,
        born_year: number
      ): EventResultController => {
        return new LeveradeWithCompetispyBackupEventResultController(
          name,
          surname,
          born_year,
          () => {
            return getAccessTokenSilently();
          }
        );
      }
    : (
        name: string,
        surname: string,
        born_year: number
      ): EventResultController => {
        return new LeveradeParticipant(name, surname, born_year);
      };

  const getTeamSummary = () => {
    return relayHelperController
      .getTeamSummaryController(teamName)
      .getSummary(year, wasmPool);
  };
  const updateTeamSummary = () => {
    updateAvailableSessions();
    setTeamSummary(getTeamSummary());
  };

  const [sessionId, setSessionId] = useState("1");
  const setSessionIdWrapper = (session: string) => {
    if (session === "new") {
      //
      const newId = (
        parseInt(availableSessions[availableSessions.length - 1]) + 1
      ).toString();
      relayHelperController?.addNewSession(teamName, newId);
      updateTeamSummary();
      setSessionId(newId);
    } else if (session === "delete") {
      const removedSessionId = availableSessions.length;
      relayHelperController?.removeSession(
        teamName,
        removedSessionId.toString()
      );
      updateTeamSummary();
      if (sessionId === removedSessionId.toString()) {
        setSessionId((removedSessionId - 1).toString());
      }
    } else {
      setSessionId(session);
    }
  };

  const [availableSessions, setAvailableSessions] = useState(["1"]);

  const getAvailableSessions = () => {
    const sessionIds = relayHelperController?.getAvailableSessionIds(teamName);
    const nSessions = sessionIds ? sessionIds.size() : 0;
    const sessions = [];
    for (let i = 0; i < nSessions; i++) {
      const entry = sessionIds?.get(i);
      if (entry) {
        sessions.push(entry.toString());
      }
    }
    return sessions;
  };

  const updateAvailableSessions = () => {
    setAvailableSessions(getAvailableSessions());
  };

  const [teamSummary, setTeamSummary] =
    useState<VectorTeamSummaryController_SummaryEntry>(getTeamSummary);

  useEffect(() => {
    (async () => {
      if (relayHelperController.isTeamLoaded(teamName)) {
        updateTeamSummary();
        return;
      }
      const res = await fetch(
        championships_api_uri + "/" + championshipId + "/teams/" + teamName
      );
      const resultJson: {
        gender: string;
        participant: {
          born_year: number;
          leverade: {
            name: string;
            surname: string;
          };
        };
        license: string | undefined;
        name: string;
      }[] = await res.json();
      const swimmerIds = resultJson
        .filter((d) => {
          return (
            d.gender.toLowerCase() === "masculino" ||
            d.gender.toLowerCase() === "femenino"
          );
        })
        .map((d): SwimmerId => {
          const gender = d.gender.toLowerCase();
          return new relayHelperCore.SwimmerId(
            d.participant.leverade.name,
            d.participant.leverade.surname,
            d.participant.born_year,
            gender === "masculino"
              ? relayHelperCore.Gender.MALE
              : relayHelperCore.Gender.FEMALE
          );
        });

      // Forces team creation
      relayHelperController.getTeamLoadController(teamName);

      const parallelExecutor = new ParallelTaskExecutor(4, setLoadingProgress);
      parallelExecutor.addTasks(
        swimmerIds.map((swimmerId) => {
          return async () => {
            await fillTimes(swimmerId);
            swimmerId.delete();
          };
        })
      );

      setShowLoading(true);
      await parallelExecutor.execute();
      setShowLoading(false);
      setLoadingProgress(0);
      setSessionId("1");
      updateTeamSummary();
    })();
  }, [teamName]);

  const fillTimes = async (swimmerId: SwimmerId) => {
    if (teamName === "") {
      return;
    }
    const swimmerSetTimeController = relayHelperController
      .getTeamLoadController(teamName)
      .getSwimmerTimesControllerForNewSwimmer(swimmerId);

    const events: { distance: 50 | 100; style: styleT }[] = [
      { distance: 50, style: "fly" },
      { distance: 50, style: "back" },
      { distance: 50, style: "breast" },
      { distance: 50, style: "free" },
      { distance: 100, style: "fly" },
      { distance: 100, style: "back" },
      { distance: 100, style: "breast" },
      { distance: 100, style: "free" },
    ];
    const styleTransf = (st: styleT): StyleWasm => {
      switch (st) {
        case "free":
          return relayHelperCore.Style.FREE;
        case "fly":
          return relayHelperCore.Style.BUTTERFLY;
        case "breast":
          return relayHelperCore.Style.BREAST;
        case "back":
          return relayHelperCore.Style.BACK;
        case "medley":
          return relayHelperCore.Style.MEDLEY;
      }
    };
    const eventResultController = eventResultControllerBuilder(
      swimmerId.name.toString(),
      swimmerId.surname.toString(),
      swimmerId.bornYear
    );
    const timePromises: Promise<GetTimeReturnType>[] = [];
    for (const event of events) {
      const promise = new Promise<GetTimeReturnType>((resolve) => {
        eventResultController.get_best_event_result(
          event.distance,
          styleToStyleMapKey(event.style),
          25,
          null,
          "all",
          (eventResult: EventResult | undefined): void => {
            if (eventResult) {
              resolve({
                time: eventResult.time,
                distance: event.distance,
                style: event.style,
              });
            }
          }
        );
      });
      const timeoutPromise = new Promise<GetTimeReturnType>(
        (resolve, reject) => {
          setTimeout(
            reject,
            4000,
            swimmerId.name.toString() +
              swimmerId.surname.toString() +
              swimmerId.bornYear.toString() +
              "_" +
              event.distance.toString() +
              "_" +
              event.style
          );
        }
      );
      timePromises.push(Promise.race([promise, timeoutPromise]));
    }
    for (const promise of timePromises) {
      try {
        const result = await promise;
        swimmerSetTimeController.setEventTime(
          {
            style: styleTransf(result.style),
            distance: result.distance,
            gender: swimmerId.gender,
          },
          result.time,
          wasmPool
        );
      } catch (e) {
        console.error("timeout", e);
      } finally {
        /* Do nothing. */
      }
    }
  };

  const toggleSwimmerAvailability = (
    swimmerId: SwimmerId,
    sessionId: string
  ) => {
    if (!relayHelperController) {
      return;
    }
    relayHelperController
      .getSwimmerToSessionController(teamName)
      .toggleSwimmerFromSession(swimmerId, sessionId);
    updateTeamSummary();
  };

  return (
    <>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <h5 className="mb-0">Nadadores</h5>
          </Accordion.Header>
          <Accordion.Body>
            <Container fluid="md">
              <Row>
                {relayHelperController && (
                  <RelaysSwimmerTable
                    key={teamName + "_summary"}
                    swimmers={teamSummary}
                    toggleSwimmerAvailability={toggleSwimmerAvailability}
                    setTime={(
                      swimmerId: SwimmerId,
                      event: Event,
                      pool: TimesPool,
                      time: number
                    ) => {
                      relayHelperController.setSwimmerTime(
                        swimmerId,
                        event,
                        pool,
                        time
                      );
                      updateTeamSummary();
                    }}
                  />
                )}
              </Row>
            </Container>
          </Accordion.Body>
        </Accordion.Item>
        {teamName !== "" && (
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              <h5 className="mb-0">Relevos</h5>
            </Accordion.Header>
            <Accordion.Body>
              <Tabs
                activeKey={sessionId}
                onSelect={(k) => setSessionIdWrapper(k ? k : "1")}
              >
                {availableSessions.sort().map((session) => (
                  <Tab
                    eventKey={session}
                    title={<>Sesión {session} </>}
                    key={`${teamName}_${session}_relays`}
                  >
                    <RelaysSession
                      teamName={teamName}
                      sessionId={parseInt(session)}
                      year={year}
                      relayHelperCore={relayHelperCore}
                      relayHelperController={relayHelperController}
                    ></RelaysSession>
                  </Tab>
                ))}
                {availableSessions.length > 1 && (
                  <Tab
                    eventKey="delete"
                    title={<FontAwesomeIcon icon={faTrash} />}
                    key={`${teamName}_delete_session_relays`}
                  >
                    <></>
                  </Tab>
                )}
                <Tab
                  eventKey="new"
                  title={<FontAwesomeIcon icon={faPlus} />}
                  key={`${teamName}_new_session_relays`}
                >
                  <></>
                </Tab>
              </Tabs>
            </Accordion.Body>
          </Accordion.Item>
        )}
      </Accordion>

      <Modal show={showLoading}>
        <Modal.Body>
          <ProgressBar
            now={loadingProgress}
            label={`${loadingProgress.toFixed(1)}%`}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RelayHelperTeam;
